body {
  margin: 0;
  font-family: Noto Serif JP, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000bf;
}

table {
  width: 97%;
  color: #383838;
  text-align: center;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

.nav button {
  color: #000000c6;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: linear-gradient(to bottom, #DDEFFF, #ffffff);
}

.bt-c {
  color: #ffffff;
  background-color: rgba(16, 12, 196, 0.87);
  box-shadow: 3px 3px 6px rgba(90, 140, 168, 0.5);
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}

.bt-c:disabled {
  /* Button styles when disabled */
  
  background-color: rgb(125, 203, 255);
  color: #ffffff;
}

.bt-jc {
  color: #ffffff;
  background-color: rgba(16, 12, 196, 0.87);
  box-shadow: 3px 3px 6px rgba(90, 140, 168, 0.5);
  border-radius: 5px;
  border: none;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}

.bt-r {
  box-shadow: 3px 3px 6px rgba(90, 140, 168, 0.5);
  border-radius: 7px;
  margin-bottom: 10px;
}

.bt-j {
  margin-bottom: 20px;
}

.bt-t {
  box-shadow: 3px 3px 6px rgba(90, 140, 168, 0.5);
}

.bt-v {
  box-shadow: 3px 3px 6px rgba(90, 140, 168, 0.5);
  padding-top: 20px;
  padding-left: 20px;
}

.logo {
  align-items: flex-end;
  /* Align items to the bottom */
  display: flex;
  margin-bottom: 10px;
}

.left {
  font-family: 'Prompt', sans-serif;
  color: #39ACC6C7;
  font-size: 40px;
}

.right {
  margin-bottom: 15px;
  color: #39ACC6C7;
}

.bo {
  width: 100%;
  height: 90vh;
  overflow: auto;
  padding-right: 1%;
}

.bt-h {
  color: "rgba(70, 71, 94, 0.66)";
  background-color: #BEE4FF;
  box-shadow: 3px 3px 6px rgba(90, 140, 168, 0.5);
  border-radius: 5px;
  border: none;
  padding: 5px 20px 5px 20px;
}


.csvupload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  height: 50vh;
  text-align: center;
}

.bt-csv {
  color: #ffffff;
  background-color:rgba(16, 12, 196, 0.87);
  box-shadow: 3px 3px 6px rgba(90, 140, 168, 0.5);
  margin-top: 5px;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}

.animation {
  position: absolute;
  height: 100%;
  /* height: 5px; */
  top: 0;
  /* bottom: 0; */
  z-index: 0;
  background: #1abc9c;
  border-radius: 8px;
  transition: all .5s ease 0s;
}

.bo-d {
  display: block;
  font-size: 1.4em;
  border-radius: 5px;
  text-align: left;
  background-color: white;
  border: none;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 30px;
}

.bo-d.active {
  background-color: rgba(95, 178, 255, 0.24);
  box-shadow: inset 3px 3px 3px rgba(90, 140, 168, 0.5);
  border: none;
  color: rgba(0, 0, 0, 0.80);
}



.bt-s {
  box-shadow: inset 0 3px 3px rgba(90, 140, 168, 0.5);
  border: none;
  border-radius: 5px;
  height: 50px;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 200px;
  text-align: center;
  font-size: 20px;
}

.fo-t {
  font-weight: 1000;
  margin-top: 20px;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.66);
}

.mi-d {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.66);
}

.warning {
  color: #dc331d;
}

.success {
  color: #309c52;
}

.csv-filename {
  border: solid 1px #7b7b7b;
  border-radius: 5px;
  padding: 2px 10px;
  color: #383838;
}

.fcon {
  width: 70%;
  margin: 20px auto 120px;
  background-color: white;
  padding: 50px;
  box-shadow: 3px 3px 6px rgba(90, 140, 168, 0.5);
}

.h-info {
  border-radius: 10px;
}

.s-hk {
  margin: 0;
}
.s-title{
  color: #5F5F5F;
  text-align: center;
}

.h-infos {
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}

.h-infom {
  text-align: center;
  border-radius: 10px;
}

.datepicker {
  padding: 10px;
  border: none;
  box-shadow: inset 0 3px 3px rgba(90, 140, 168, 0.5);
  color: #383838;
  text-align: center;

}

.rounded-left {
  border-radius: 5px 0 0 5px;
}

.rounded-right {
  border-radius: 0 5px 5px 0;
}

/* register */

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #0033ffb7;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}
/* End user */
.db-button {
  border: none;
  margin: 70px auto;
  padding: 30px;
  width: 30%;
  display: block;
  color: #2E4E7F;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #ffffff;
  box-shadow: 3px 3px 6px rgba(90, 140, 168, 0.5);
  border-radius: 15px;
}

.e-ti{
  background: #D8E5F4;
}

.e-pr{
  padding-left: 100px;
}




